import * as React from 'react';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate
} from "react-router-dom";
import { withTranslation } from 'react-i18next';

//components
import Alert from './Components/Alert';

// Redux
import { useAppDispatch } from './Hooks/customSelector';
import { setUser } from './Redux/Reducers/userReducer';

// Models
import Admin from './Models/Admin';

// *****Pages******* 

// Core
import AdminCore from './Core/Admin';
import Auth from './Core/Auth';

// Track
import Track from './Pages/Track';

// 404 
import NotFound from './Pages/NotFound';

// Dashboard
import Dashboard from './Pages/Dashboard';

// Login
import Login from './Pages/Login';
 
// Member
import Member from './Pages/Member';
import MemberList from './Pages/Member/list';
import MemberEdit from './Pages/Member/edit';

// Admin

import AdminConfig from './Pages/Admin';
import AdminList from './Pages/Admin/admin';
import MemberWaitingDelivery from './Pages/Member/waitingValidation';


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
type ProtectedRouteProps = { 
  outlet: JSX.Element;
};

type RolesProtectedRouteProps = { 
  outlet: JSX.Element;
  rolesRequired: Array<String>;
};

function ProtectedRoute({ outlet }: ProtectedRouteProps) {
  const dispatch = useAppDispatch();
  const user = window.localStorage.getItem('app_user');
  if( user !== null && user !== undefined ) {
    dispatch( setUser( Admin.fromJson(  JSON.parse(user)  ) ) ); 
    return outlet;
  } else {
    window.location.href = "/auth"
    return <Navigate to={{ pathname: '/auth' }}  replace={true} />;
  }
};

function RoleRequiredRoute( { outlet, rolesRequired  }: RolesProtectedRouteProps ) { 

  const user: any = window.localStorage.getItem('app_user');
  if( user !== null && user !== undefined ) { 
    if (rolesRequired.includes((JSON.parse(user)).role ?? '')) {
      return outlet;
    } else {
      window.location.href = "/"
      return <Navigate to={{ pathname: '/' }}  replace={true} />;
    }
    
  } else {
    window.location.href = "/"
    return <Navigate to={{ pathname: '/' }}  replace={true} />;
  }

};


// const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
//   isAuthenticated: true,
//   authenticationPath: '/auth',
// };

const App = () => {

  return (
    // basename="/poten"
    
      <Router  >
        <Track/>
        
        <Routes >
          <Route path="/" element={ <AdminCore />} >

            <Route index element={<ProtectedRoute  outlet={ <Dashboard />} />} />

            {/* Agent Menu */}
            <Route path="member" element={<ProtectedRoute  outlet={ <Member />} />} >
              <Route index element={<ProtectedRoute  outlet={ <MemberList />} />} />
              <Route path="list" element={<ProtectedRoute  outlet={ <MemberList />} />} />
              <Route path="edit/:id" element={<ProtectedRoute  outlet={ <MemberEdit />} />} />
              <Route path="waiting-delivery" element={<ProtectedRoute  outlet={ <RoleRequiredRoute outlet={<MemberWaitingDelivery />} rolesRequired={[ 'ROOT', 'SUPERADMIN'  ]} /> } />} />
              {/* <Route path="create" element={<ProtectedRoute  outlet={ <AgentCreate  />} />} />
               */}
            </Route>

             {/* Agent Menu */}
             <Route path="admin" element={<ProtectedRoute  outlet={ <RoleRequiredRoute outlet={<AdminConfig />} rolesRequired={[ 'ROOT', 'SUPERADMIN'  ]} /> } />} >
              <Route index element={<ProtectedRoute  outlet={ <RoleRequiredRoute outlet={<AdminList />} rolesRequired={[ 'ROOT', 'SUPERADMIN'  ]} /> } />} />
              <Route path="list" element={<ProtectedRoute  outlet={ <RoleRequiredRoute outlet={<AdminList />} rolesRequired={[ 'ROOT', 'SUPERADMIN'  ]} /> } />} />
              {/* <Route path="edit/:id" element={<ProtectedRoute  outlet={ <MemberEdit />} />} /> */}
              {/* <Route path="create" element={<ProtectedRoute  outlet={ <AgentCreate  />} />} />
               */}
            </Route>

          </Route>  
          <Route path="/auth" element={ <Auth />} >
            <Route index element={<Login />} />
          </Route> 
          <Route path="*" element={ <NotFound />} />
        </Routes>
      </Router> 
  );
}

export default  App;
