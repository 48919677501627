import { FC, useEffect, useState } from 'react';
import menu from '../Utils/menu';
import { useAppSelector, useAppDispatch } from '../Hooks/customSelector';
import { RootState } from '../Redux/store';
import {
    Link
  } from "react-router-dom";
import { MenuItem } from '../Models/Menu';
import { setUser } from '../Redux/Reducers/userReducer';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import the icons you need


const Sidebar: FC<{  }> = (  ) => { 
    const routes = useAppSelector((state) => state.routes);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state: RootState) => state.users.user );
    let filteredMenuInit: Array<MenuItem> = [];
    let [ filteredMenu, setFilteredMenu ] = useState(filteredMenuInit);

    const getAcitveMenu = (menuItem: MenuItem ): boolean => {
        if (routes.currentPath == menuItem.link) return true;
        if (menuItem.subMenu.length > 0) {
            for (let i = 0; i <= (menuItem.subMenu.length - 1); i++) {
                if (menuItem.subMenu[i].link == routes.currentPath) return true;
            }
        }
        return false;
    }


    // const getAcitveMenu = ( parentPath: string, subPaths: Array<string> = [] ): boolean => {
    //     if (routes.currentPath == parentPath) return true;
    //     if (subPaths.length > 0) {
    //         for (let i = 0; i <= (subPaths.length - 1); i++) {
    //             if (subPaths[i] == routes.currentPath) return true;
    //         }
    //     }
    //     return false;
    // }

    // const getMenuFiltered = () => {
    //     let userStore = JSON.parse(window.localStorage.getItem('app_user') ?? '');
    //     const menuFiltered = menu.menu.filter(
    //         (menuItem) => menuItem.permissions.length === 0 || menuItem.permissions.includes(userStore.role ?? '')
    //     );
    //     console.log(menuFiltered);
    //     setFilteredMenu(() => menuFiltered);
    // }

    // useEffect(() => {
    //     getMenuFiltered();
    // }, [])


        return (  
            <aside className="left-sidebar" data-sidebarbg="skin6">
                <div className="scroll-sidebar" data-sidebarbg="skin6">
                <nav className="sidebar-nav">
                         <ul id="sidebarnav">
                                {
                                menu.menu.map(
                                    (menuItem, id) => menuItem.permissions.includes( user?.role ?? '' ) || 
                                    menuItem.permissions.length === 0 ? (
                                        
                                         <li  key={id} className={`sidebar-item ${ getAcitveMenu(menuItem) ? "selected" : "" } `}> 
                                         <Link className={`sidebar-link ${ menuItem.subMenu.length > 0 ? "has-arrow" : "" }`}  
                                         to={`${ menuItem.link != "" ? menuItem.link : "javascript:void(0)" }`} 
                                         aria-expanded="false">
                                            {/* <i data-feather={`${ menuItem.icon }`} ></i> */}
                                            <FontAwesomeIcon icon={menuItem.icon} style={{ fontSize: 17, color: "white", marginRight: '3px' }}
                                             className={`${ getAcitveMenu(menuItem) ? "icon-selected" : "icon-not-selected" } `}  />
                                        <span
                                            className="hide-menu"> 
                                                { t(t('menu', { menuName: menuItem.name } ) ?? '') }

                                            </span></Link>
                                            {   menuItem.subMenu.length > 0 ? 
                                                <ul aria-expanded="false" className="collapse first-level base-level-line">
                                                    {
                                                        menuItem.subMenu.map(
                                                            (subMenuItem, idi) => subMenuItem.permissions.includes( user?.role ?? '' ) || 
                                                            subMenuItem.permissions.length === 0 ? (
                                                                <li key={idi} className={`sidebar-item ${ getAcitveMenu(subMenuItem) ? 
                                                                    "active" : "" } `}>
                                                                    <Link to={`${ subMenuItem.link }`} className="sidebar-link"><span
                                                                            className="hide-menu"> 
                                                                            { t(t('submenu', { subMenuName: subMenuItem.name } ) ?? '') }
                                                                        </span>
                                                                    </Link>
                                                                </li> 
                                                        ) : ( <></> ) )
                                                    }
                                                    
                                                </ul> : null
                                            }
                                        </li> 
                                    ) : ( <></> ) )
                                 
                            } 
                            
                        </ul> 
                    </nav> 
                </div> 
            </aside>
        )   
}

export default Sidebar